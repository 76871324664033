import {post, get} from "./ajax";
import {hideElement, slideUp, fadeIn, fadeOut, showElement} from "./element-visibility";
import {createOffcanvasFromHtml, getOffcanvas} from "./offcanvas";
import {addItem, addItems} from "./cart";
import Splide from "@splidejs/splide";
import {fireChangeEvent} from "./events";
import {
    dataLayerAddProduct,
    ecAddProduct,
    ecSetAction,
    ga4TrackSimpleEvent,
    sendFacebookConversionsEvent,
    trackEvent
} from "./analytics";
import {getPriceFormat} from "../modules-optimized/price-format";

let ajaxSendCalculatePrice = null;
let ajaxSendCalculateMultiPrice = null;

let timerCalculatePrice = null;
export function calculatePrice(scope, applyComplements, success, error)
{
    applyComplements = false;
    if (ajaxSendCalculatePrice) hideLoader(scope);
    clearTimeout(timerCalculatePrice);
    timerCalculatePrice = setTimeout(function() {
        if (isMUltiSizeProduct(scope)) {
            let products = getMultiSizeProducts(scope);
            if (!products.length) return;
            ajaxCalculateMultiSizePrice(
                products,
                function (response) {
                    hideLoader(scope);
                    if (response.error) {
                        if (error) error(response.error);
                        return;
                    }

                    let price = response.price;

                    if (applyComplements) {
                        complements.forEach(function (complement) {
                            price += parseFloat(complement.price) * parseInt(complement.quantity);
                        });
                        crossSellingProducts.forEach(function (crossSellingProduct) {
                            price += parseFloat(crossSellingProduct.price) * parseInt(crossSellingProduct.quantity);
                        });
                    }

                    if (success) success(price, response.warning);
                },
                function() {
                    hideLoader(scope);
                }
            );
        } else {
            let productId = scope.dataset.productId;
            let quantity = getQuantity(scope);
            let options = getOptions(scope);
            let complements = getComplements(scope);
            let crossSellingProducts = getCrossSellingProducts(scope);
            if (validSizeOptions(options)) {
                showLoader(scope);
                ajaxCalculatePrice(
                    productId,
                    quantity,
                    options,
                    function (response) {
                        hideLoader(scope);
                        if (response.error) {
                            if (error) error(response.error);
                            return;
                        }

                        let priceDecimals = response.price % 1 ? response.price.toString().split(".")[1].length : 0;
                        let price = response.price * quantity;

                        if (applyComplements) {
                            complements.forEach(function (complement) {
                                price += parseFloat(complement.price) * parseInt(complement.quantity);
                            });
                            crossSellingProducts.forEach(function (crossSellingProduct) {
                                price += parseFloat(crossSellingProduct.price) * parseInt(crossSellingProduct.quantity);
                            });
                        }

                        if (success) success(price.toFixed(priceDecimals), response.warning);
                    },
                    function() {
                        hideLoader(scope);
                    }
                )
            }
        }
    }, 200);
}

export function ajaxCalculatePrice(productId, quantity, options, success, error)
{
    if (ajaxSendCalculatePrice) ajaxSendCalculatePrice.abort();
    ajaxSendCalculatePrice = post(
        '/ajax/website/product/calculate-price',
        {
            productId: productId,
            quantity: quantity,
            options: options
        },
        function (response) {
            ajaxSendCalculatePrice = null;
            success(response);
        },
        function() {
            ajaxSendCalculatePrice = null;
            error();
        }
    );
}

export function ajaxCalculateMultiSizePrice(products, success, error)
{
    if (ajaxSendCalculatePrice) ajaxSendCalculatePrice.abort();
    ajaxSendCalculatePrice = post(
        '/ajax/website/product/calculate-multi-size-price',
        { products: products },
        function (response) {
            ajaxSendCalculatePrice = null;
            success(response);
        },
        function() {
            ajaxSendCalculatePrice = null;
            error();
        }
    );
}

export function ajaxCalculateMultiPrices(data)
{
    if (ajaxSendCalculateMultiPrice) ajaxSendCalculateMultiPrice.abort();
    return new Promise((resolve) => {
        ajaxSendCalculateMultiPrice = post(
            '/ajax/website/product/calculate-multiple-prices',
            {
                data: data
            },
            function (response) {
                resolve(response);
            }
        );
    });
}

function validSizeOptions(options)
{
    let valid = !(((!options['width'] || !parseFloat(options['width'])) || (!options['height'] || !parseFloat(options['height']))) &&
        !options['device'] &&
        !options['composition'] &&
        !options['model']);

    return valid;
}

export function getMultiSizeProducts(scope)
{
    let products = [];
    let productId = scope.dataset.productId;
    let parentId = scope.dataset.parent;
    let selectedSizes = scope.querySelectorAll('.options[data-type="size"] .option-value:checked');
    selectedSizes.forEach(function(size, index) {
        let sizeOption = size.closest('.options[data-type="size"]');
        let productIndex = sizeOption.dataset.productIndex;
        let quantity = size.dataset.quantity;
        let options = getOptions(scope, index);
        let product = {
            productId: productId,
            parentId: parentId,
            quantity: quantity,
            options: options,
        };
        if (parentId) product['linkToParent'] = true;
        if (index == selectedSizes.length - 1) {
            let complements = getComplements(scope);
            let crossSellingProducts = getCrossSellingProducts(scope);
            product['extraProducts'] = complements.concat(crossSellingProducts);
        }

        products.push(product);
    });
    if (!products.length) {
        products.push({
            productId: productId,
            quantity: 1.
        });
    }

    return products;
}

export function getOptions(scope, multiSizeProductIndex)
{
    let options = [];
    options['multi'] = [];
    if (document.referrer.indexOf(location.protocol + "//" + location.host) === 0) options['referrer'] = document.referrer;

    let productIndexOptions = scope.querySelectorAll('.pi-options');
    if (!productIndexOptions.length) productIndexOptions = [scope]; //TODO TEMPORAL FIX

    //ADD NORMAL OPTIONS
    if (scope.dataset.useDataAttributes === 'true') {
        if (scope.dataset.width) options['width'] = scope.dataset.width;
        if (scope.dataset.height) options['height'] = scope.dataset.height;
        if (scope.dataset.composition) options['composition'] = scope.dataset.composition;
        if (scope.dataset.pack) options['pack'] = scope.dataset.pack;
        if (scope.dataset.color) options['color'] = scope.dataset.color;
        if (scope.dataset.image) options['personalizedImage'] = scope.dataset.image;
        if (scope.dataset.originalProduct) options['originalProduct'] = scope.dataset.originalProduct;
    } else {
        productIndexOptions.forEach(function(productOptions) {
            let productIndex = productOptions.dataset.productIndex;
            if (!productIndex) productIndex = 1; //TODO TEMPORAL FIX
            if (productIndex > 1) options['multi'][productIndex] = [];
            productOptions.querySelectorAll('.options').forEach(function(option) {
                switch (option.dataset.type) {
                    case 'size':
                        addSizeOptions(options, option, productIndex, multiSizeProductIndex);
                        break;
                    case 'customizer':
                        addCustomizeOptions(options, option, productIndex);
                        break;
                    case 'color':
                        addColorOptions(options, option, productIndex);
                        break;
                    case 'pack':
                        addPackOptions(options, option, productIndex);
                        break;
                    case 'orientation':
                        addOrientationOptions(options, option, productIndex);
                        break;
                    case 'glass-reversed':
                        addGlassReversedOptions(options, option, productIndex);
                        break;
                    case 'blind-tissue':
                        addBlindTissueOptions(options, option, productIndex);
                        break;
                    case 'blind-chain':
                        addBlindChainOptions(options, option, productIndex);
                        break;
                    case 'blind-drop':
                        addBlindDropOptions(options, option, productIndex);
                        break;
                    case 'blind-support-color':
                        addBlindSupportColorOptions(options, option, productIndex);
                        break;
                    case 'model':
                        addModelOptions(options, option, productIndex);
                        break;
                    case 'material-color':
                        addMaterialColorOptions(options, option, productIndex);
                        break;
                    case 'model-size':
                        addModelSizeOptions(options, option, productIndex);
                        break;
                    case 'poster-frame':
                        addPosterFrameOptions(options, option, productIndex);
                }
            });
        });
    }

    productIndexOptions.forEach(function (productOptions) {
        let productIndex = productOptions.dataset.productIndex;
        if (!productIndex) productIndex = 1; //TODO TEMPORAL FIX
        //ADD SINGLE VALUE OPTION
        productOptions.querySelectorAll('.option-single-value').forEach(function(option) {
            switch (option.dataset.type) {
                case 'size':
                    addSizeOptions(options, option, productIndex);
                    break;
                case 'customizer':
                    addCustomizeOptions(options, option, productIndex);
            }
        });
        //ADD HIDDEN OPTIONS
        productOptions.querySelectorAll('.hidden-option').forEach(function (option) {
            addOption(options, productIndex, option.dataset.name, option.value);
        });
    });


    return options;
}

function addPosterFrameOptions(options, frameOption, productIndex)
{
    let selectedFrame = frameOption.querySelector('input[name="poster-frame"]:checked');

    addOption(options, productIndex, 'frame', selectedFrame ? selectedFrame.value : '');
}

function addSizeOptions(options, productOption, productIndex, multiSizeProductIndex)
{
    let width = null;
    let height = null;
    let personalizedSize = false;
    let device = null;
    let composition = null;
    let selectedOption = productOption.querySelector('.option-value:checked, input[type="hidden"].option-value');
    if (multiSizeProductIndex) selectedOption = productOption.querySelectorAll('.option-value:checked, input[type="hidden"].option-value')[multiSizeProductIndex];

    if (selectedOption) {
        switch (selectedOption.value) {
            case 'custom-size':
            case 'both-size':
                width = selectedOption.dataset.width;
                height = selectedOption.dataset.height;
                personalizedSize = true;
                break;
            default:
                width = selectedOption.dataset.width;
                height = selectedOption.dataset.height;
                device = selectedOption.dataset.device;
                composition = selectedOption.dataset.composition;
                break;
        }
    }

    if (width && width !== '') addOption(options, productIndex, 'width', width);
    if (height && height !== '') addOption(options, productIndex, 'height', height);
    if (device && device !== '') addOption(options, productIndex, 'device', device);
    if (composition && composition !== '') addOption(options, productIndex, 'composition', composition);
    addOption(options, productIndex, 'personalizedSize', personalizedSize)
}

function addCustomizeOptions(options, customizeOption, productIndex)
{
    let customImage = customizeOption.querySelector('input[name="custom-image"]');
    let customText = customizeOption.querySelector('textarea[name="customText"], input[name="customText"]');
    let customImageFromWeb = customizeOption.querySelector('input[name="customImageFromWeb"]');
    let svgPersonalizedText = customizeOption.querySelector('input[name="svg-personalized-text"]');
    let hasCustomPriceIncrement = customizeOption.querySelector('input[name="customPriceIncrement"]');
    let personalizedSvg = customizeOption.querySelector('input[name="personalizedSvg"]');

    addOption(options, productIndex, 'personalizedImage', customImage ? customImage.value : null);
    addOption(options, productIndex, 'personalizedText', customText ? customText.value : null);
    addOption(options, productIndex, 'personalizedImageFromWeb', customImageFromWeb && customImageFromWeb.checked ? true : null);
    if (svgPersonalizedText) {
        if (svgPersonalizedText.dataset.svg) addOption(options, productIndex, 'personalizedImage', svgPersonalizedText.dataset.svg);
        if (svgPersonalizedText.dataset.preview) addOption(options, productIndex, 'personalizedImagePreview', svgPersonalizedText.dataset.preview);
    }
    if (personalizedSvg) addOption(options, productIndex, 'personalizedSvg', personalizedSvg.value);
    if (hasCustomPriceIncrement) {
        let customPriceIncrement = customizeOption.querySelector('input[name="customPriceIncrement"]:checked');
        if (customPriceIncrement) {
            if (options['personalizedText'] || options['personalizedImage']) {
                addOption(options, productIndex, 'customPriceIncrement', true);
                if (options['personalizedImage'] && customText && !options['personalizedText']) {
                    addOption(options, productIndex, 'personalizedText', '-');
                }
            }
        } else {
            if (customText) addOption(options, productIndex, 'personalizedText', '-');
            if (svgPersonalizedText) {
                addOption(options, productIndex, 'personalizedText', '-');
                svgPersonalizedText.dataset.svg = '';
                addOption(options, productIndex, 'personalizedImage', svgPersonalizedText.dataset.svg);
                if (svgPersonalizedText.dataset.preview) {
                    svgPersonalizedText.dataset.preview = '';
                    addOption(options, productIndex, 'personalizedImagePreview', svgPersonalizedText.dataset.preview);
                }
            }
            if (customImage && customImageFromWeb) addOption(options, productIndex, 'personalizedImageFromWeb', true);
        }
    }
}

function addColorOptions(options, colorOption, productIndex)
{
    let selectedColor = colorOption.querySelector('input[name="color"]:checked');
    if (selectedColor) addOption(options, productIndex, 'color', selectedColor.value);
}

function addPackOptions(options, packOption, productIndex)
{
    let selectedPack = packOption.querySelector('input[type="radio"]:checked');
    if (selectedPack) addOption(options, productIndex, 'pack', selectedPack.value);
}

function addOrientationOptions(options, orientationOption, productIndex)
{
    let selectedOrientation = orientationOption.querySelector('input[name="orientation"]:checked');
    if (selectedOrientation) addOption(options, productIndex, 'mirrored', selectedOrientation.value === 'mirrored');
}

function addGlassReversedOptions(options, glassReversedOption, productIndex)
{
    let selectedGlassReversed = glassReversedOption.querySelector('input[name="glass-reversed"]:checked');
    if (selectedGlassReversed) addOption(options, productIndex, 'reversed', true);
}

function addBlindChainOptions(options, blindChainOption, productIndex)
{
    let selectedMechanism = blindChainOption.querySelector('input[name="mechanism"]');
    let selectedChainPosition = blindChainOption.querySelector('input[name="chainPosition"]:checked');
    if (selectedMechanism) addOption(options, productIndex, 'mechanism', selectedMechanism.value);
    if (selectedChainPosition) addOption(options, productIndex, 'chainPosition', selectedChainPosition.value);
}

function addBlindDropOptions(options, blindDropOption, productIndex)
{
    let selectedDrop = blindDropOption.querySelector('input[name="drop"]:checked');
    if (selectedDrop) addOption(options, productIndex, 'drop', selectedDrop.value);
}

function addBlindSupportColorOptions(options, blindSupportColorOption, productIndex)
{
    let selectedSupportColor = blindSupportColorOption.querySelector('input[name="support-color"]:checked');
    addOption(options, productIndex, 'supportColor', selectedSupportColor ? selectedSupportColor.value : '');
}

function addBlindTissueOptions(options, blindTissueOption, productIndex)
{
    let selectedTissue = blindTissueOption.querySelector('input[name="tissue"]:checked');
    if (selectedTissue) addOption(options, productIndex, 'tissue', selectedTissue.value);
}

function addModelOptions(options, modelOption, productIndex)
{
    let selectedModel = modelOption.querySelector('input[name="model"]:checked');
    if (selectedModel) addOption(options, productIndex, 'model', selectedModel.value);
}

function addMaterialColorOptions(options, materialColorOption, productIndex)
{
    let selectedMaterialColor = materialColorOption.querySelector('input[name="material-color"]:checked');
    if (selectedMaterialColor) addOption(options, productIndex, 'materialColor', selectedMaterialColor.value);
}

function addModelSizeOptions(options, modelOption, productIndex)
{
    let selectedModelSize = modelOption.querySelector('input[name="model-size"]:checked');
    if (selectedModelSize) addOption(options, productIndex, 'modelSize', selectedModelSize.value);
}

function addOption(options, productIndex, name, value)
{
    if (productIndex == 1) options[name] = value;
    else options['multi'][productIndex][name] = value;
}

function getComplements(scope)
{
    let complements = [];
    let productComplements = scope.querySelectorAll('.product-complements:checked');

    productComplements.forEach(function (complement) {
        if (complement.dataset.alreadyAdded) {
            return;
        }

        if (complement.id === 'checkbox-up-selling-personalized-text') {
            complements.push(upSellingPersonalizedTextData(complement));
        } else {
            complements.push(
                {
                    id: complement.value,
                    price: complement.dataset.price,
                    quantity: complement.dataset.quantity,
                    isLocked: complement.dataset.locked,
                    linkToParent: complement.dataset.linkToParent,
                    options: {
                        width: complement.dataset.width,
                        height: complement.dataset.height,
                        device: complement.dataset.device,
                        composition: complement.dataset.composition
                    }
                }
            );
        }
    });

    return complements;
}

function getCrossSellingProducts(scope)
{
    let crossSellingProducts = [];
    let selectedCrossSellingProducts = scope.querySelectorAll('.product-cross-selling:checked');

    selectedCrossSellingProducts.forEach(function (crossSellingProduct) {
        crossSellingProducts.push(
            {
                id: crossSellingProduct.value,
                name: crossSellingProduct.dataset.name,
                price: crossSellingProduct.dataset.price,
                quantity: crossSellingProduct.dataset.quantity,
                linkToParent: crossSellingProduct.dataset.linkToParent,
                options: {
                    width: crossSellingProduct.dataset.width,
                    height: crossSellingProduct.dataset.height,
                    device: crossSellingProduct.dataset.device,
                    composition: crossSellingProduct.dataset.composition,
                    originalProduct: crossSellingProduct.dataset.originalProduct,
                    color: crossSellingProduct.dataset.color,
                    personalizedImage: crossSellingProduct.dataset.image,
                    pack: crossSellingProduct.dataset.pack
                }
            }
        );
    });

    return crossSellingProducts;
}

export function displayPrice(scope, price, oldPrice = null)
{
    price = getPriceFormat(price);
    scope.querySelectorAll('.price .price-value').forEach(function (element) {
        element.innerHTML = price;
    });

    scope.querySelectorAll('.price .price-of').forEach(function(element) {
        element.classList.add('d-none');
    })

    if (oldPrice) {
        oldPrice = getPriceFormat(oldPrice);
        scope.querySelectorAll('.old-price').forEach(function (element) {
            let number = element.querySelector('.number');
            if (number) number.innerHTML = oldPrice;
            if (element.classList.contains('d-none')) element.classList.remove('d-none');
        });

        scope.querySelectorAll('.price .price-text').forEach(function (element) {
            if (!element.classList.contains('text-danger')) element.classList.add('text-danger');
        });
    } else {
        scope.querySelectorAll('.old-price').forEach(function (element) {
            if (!element.classList.contains('d-none')) element.classList.add('d-none');
        });
        scope.querySelectorAll('.price .price-text').forEach(function (element) {
            if (element.classList.contains('text-danger')) element.classList.remove('text-danger');
        });
    }
}

function getQuantity(scope)
{
    let input = 1;
    scope.querySelectorAll('.quantity-add-to-cart').forEach(function(element) {
        if (element.value > input) input = element.value;
    });

    scope.querySelectorAll('.options[data-type="size"] .option-value:checked').forEach(function(size) {
         let quantity = size.dataset.quantity;
         if (!quantity) return;
         quantity = parseInt(quantity);
         if (quantity > 0 && quantity > input) input = quantity;
    });

    return input;
}

export function resetQuantity(scope)
{
    scope.querySelectorAll('.quantity-add-to-cart').forEach(function(element) {
        if (element) element.value = element.min || 1;
    });
}

export function resetPriceButtons(scope)
{
    scope.querySelectorAll('.price .price-value').forEach(function (element) {
        let defaultPrice = element.dataset.defaultPrice;
        if (defaultPrice){
            defaultPrice = getPriceFormat(defaultPrice);
            element.innerHTML = defaultPrice;
        }
    });

    scope.querySelectorAll('.price .price-of').forEach(function(element) {
        element.classList.remove('d-none');
    });

    let oldPrices = scope.querySelectorAll('.old-price');
    oldPrices.forEach(function(element) {
        element.classList.remove('d-none');
    });

    if (oldPrices.length) {
        scope.querySelectorAll('.old-price .number').forEach(function(element) {
            let defaultOldPrice = element.dataset.defaultOldPrice;
            if (defaultOldPrice) {
                defaultOldPrice = getPriceFormat(defaultOldPrice);
                element.innerHTML = defaultOldPrice;
            }
        });

        scope.querySelectorAll('.price .price-text').forEach(function (element) {
            if (!element.classList.contains('text-danger')) element.classList.add('text-danger');
        });
    }
}

function showLoader(scope)
{
    scope.querySelectorAll('.btn-add-to-cart').forEach(function(element) {
        element.disabled = true;
        let loader = element.querySelector('.loader');
        let elementToHide = element.querySelector('.text');
        elementToHide.classList.add('d-none');
        if (loader) loader.classList.remove('d-none');
    });
}

function hideLoader(scope)
{
    scope.querySelectorAll('.btn-add-to-cart').forEach(function(element) {
        element.disabled = false;
        let loader = element.querySelector('.loader');
        let elementToHide = element.querySelector('.text');
        if (loader) loader.classList.add('d-none');
        elementToHide.classList.remove('d-none');
    });
}

export function getPreview(productOption) {
    let type = productOption.dataset.type;
    let optionType = productOption.dataset.optionType;
    let preview = '';
    switch (type) {
        case 'size':
            if (optionType == 'multiple-selectable') {
                let selectedOptions = productOption.querySelectorAll('.option-value:checked');
                if (!selectedOptions) return;
                preview = '<ul class="list-unstyled m-0">';
                selectedOptions.forEach(function(selectedOption, index) {
                    preview += "<li>" + getSizePreviewText(selectedOption) + "</li>";
                });
                preview += "</ul>";
            } else {
                let selectedOption = productOption.querySelector('.option-value:checked');
                if (!selectedOption) return;
                preview = getSizePreviewText(selectedOption);
            }
            break;
        case 'customizer':
             let customImage = productOption.querySelector('input[name="display-custom-image"]');
             let customImageFromWeb = productOption.querySelector('input[name="customImageFromWeb"]');
             let customText = productOption.querySelector('textarea[name="customText"], input[name="customText"]');
             if (customText && customText.value.length > 0 && !customImage) preview = customText.value;
             if (customImage && customImage.value && !customText) preview = customImage.value;
             if (customImageFromWeb && customImageFromWeb.checked && !customText) preview = customImageFromWeb.dataset.preview;
             if (customText && customText.value !== '' && (customImage && customImage.value !== '' || customImageFromWeb && customImageFromWeb.checked)) {
                 preview = customText.dataset.preview + ': ' + customText.value + '<br>';
                 if (customImage && customImage.value !== '') preview += customImage.dataset.preview + ': ' + customImage.value;
                 if (customImageFromWeb && customImageFromWeb.checked) preview += customImage.dataset.preview + ': ' + customImageFromWeb.dataset.preview;
             } else if (customText && !customText.value && (customImage && customImage.value !== '' || customImageFromWeb && customImageFromWeb.checked)) {
                 preview = customText.dataset.preview + ': <br>';
                 if (customImage && customImage.value !== '') preview += customImage.dataset.preview + ': ' + customImage.value;
                 if (customImageFromWeb && customImageFromWeb.checked) preview += customImage.dataset.preview + ': ' + customImageFromWeb.dataset.preview;
             }
             break;
        case 'color':
             let selectedColor = productOption.querySelector('input[name="color"]:checked');

             if (selectedColor) {
                 let previewColor = selectedColor.dataset.previewColor;
                 let previewName = selectedColor.dataset.previewName;
                 preview = '';
                 preview += '<span class="display-color d-inline-block align-middle rounded-circle border border-dark" style="background: '+previewColor+'" title="'+previewName+'"></span>';
                 preview += '<span class="align-middle"> '+previewName+'</span>'
             }
             break;
        case 'pack':
            let selectedPack = productOption.querySelector('input[name="pack"]:checked');
            preview = selectedPack.dataset.preview;
            break;
        case 'blind-tissue':
            let selectedTissue = productOption.querySelector('input[name="tissue"]:checked');
            preview = selectedTissue.dataset.preview;
            break;
        case 'blind-chain':
            let selectedChainPosition = productOption.querySelector('input[name="chainPosition"]:checked');
            preview = selectedChainPosition.dataset.preview;
            break;
        case 'blind-drop':
            let selectedDrop = productOption.querySelector('input[name="drop"]:checked');
            preview = selectedDrop.dataset.preview;
            break;
        case 'blind-support-color':
            let selectedSupportColor = productOption.querySelector('input[name="support-color"]:checked');
            preview = selectedSupportColor.dataset.preview;
            break;
        default:
           break;
    }

    return preview;
}

function getSizePreviewText(selectedOption)
{
    let preview = '';
    let um = selectedOption.dataset.unitSize;
    let size = selectedOption.dataset.size;
    let width = selectedOption.dataset.width;
    let height = selectedOption.dataset.height;
    let previewText = selectedOption.dataset.previewText;
    let parent = selectedOption.parentElement;
    let quantity = selectedOption.nextElementSibling.querySelector('input[name="quantity"], select.quantity') || parent.querySelector('input[name="quantity"], select.quantity');
    if (previewText) preview = previewText;
    else if (size) preview = size;
    else if (width && height) preview = width+' x '+height+' '+um;
    if (quantity && !quantity.classList.contains('disabled') && preview !== '') preview += '&nbsp; &nbsp; x ' + quantity.value;

    return preview;
}

let ajaxProportionalHeight = null;
let timerProportionalHeight = null;
export function getProportionalHeight(width, scope, success, error)
{
    if (!width) return;
    let productId = scope.dataset.productId;
    let factor = null;
    let factorInput = scope.querySelector('input[name="factor"]');
    if (factorInput) factor = factorInput.value;
    if (ajaxProportionalHeight) ajaxProportionalHeight.abort();
    clearTimeout(timerProportionalHeight);
    timerProportionalHeight = setTimeout(function() {
        ajaxProportionalHeight = post(
            '/ajax/website/product/get-proportional-height',
            {
                width: width,
                productId: productId,
                factor: factor,
            },
            function (response) {
                success(response.height);
            },
            function (response) {
                error(response.sizesMessage)
            }
        );
    }, 200);
}

export function validateWidthLimits(width)
{
    let minWidth = parseFloat(width.dataset.min);
    let maxWidth = parseFloat(width.dataset.max);

    if (width.value === '') return null;
    if (parseFloat(width.value) < minWidth) return 'min';
    if (parseFloat(width.value) > maxWidth) return 'max';
    return null;
}

export function validateHeightLimits(height)
{
    let minHeight = parseFloat(height.dataset.min);
    let maxHeight = parseFloat(height.dataset.max);

    if (height.value === '') return null;
    if (parseFloat(height.value) < minHeight) return 'min';
    if (parseFloat(height.value) > maxHeight) return 'max';
    return null;
}

export function validateSizeLimits(width, height)
{
    let minWidth = parseFloat(width.dataset.min);
    let minHeight = parseFloat(height.dataset.min);
    let maxWidth = parseFloat(width.dataset.max);
    let maxHeight = parseFloat(height.dataset.max);

    if (width.value === '' || height.value === '') return null;
    if (parseFloat(width.value) < minWidth || parseFloat(height.value) < minHeight) return 'min';
    if (parseFloat(width.value) > maxWidth && parseFloat(height.value) > maxHeight) return 'max';
    return null;
}

export function addToCart(scope, success, error)
{
    showLoader(scope);
    let productId = scope.dataset.productId;
    let parentId = scope.dataset.parent;
    let quantity = getQuantity(scope);
    let options = getOptions(scope);
    let complements = getComplements(scope);
    let crossSellingProducts = getCrossSellingProducts(scope);
    let data = {
        productId: productId,
        parentId: parentId,
        quantity: quantity,
        options: options,
        extraProducts: complements.concat(crossSellingProducts)
    };

    if (parentId) data['linkToParent'] = true;

    addItem(
        data,
        function(items) {
            hideLoader(scope);
            success(items);
        },
        function(productIndex, type, message, productMessage) {
            hideLoader(scope);
            error(productIndex, type, message, productMessage);
        }
    );
}

export function isMUltiSizeProduct(scope)
{
    return scope.querySelectorAll('.options[data-type="size"] .option-value:checked').length > 1;
}

export function addToCartMultiSize(scope, success, error)
{
    showLoader(scope);
    let products = getMultiSizeProducts(scope);

    addItems(
        products,
        function(items) {
            hideLoader(scope);
            success(items);
        },
        function(productIndex, type, message, productMessage) {
            hideLoader(scope);
            error(productIndex, type, message, productMessage);
        }
    );
}

// @deprecated
export function loadProductAddedOffCanvas(itemId, parentElement, callback)
{
    post(
        '/ajax/website/product/offcanvas-product-added-to-cart',
        {itemId: itemId},
        function(response) {
            let offcanvas = createOffcanvasFromHtml(response.offcanvas, parentElement);
            let slider = document.querySelector('#product-added-to-cart .cross-selling-slider');
            let splide = new Splide(slider, {
                perPage: 2,
                gap: "13px",
                pagination: false,
                padding: { right: '20%'},
                arrows: false,
            });
            splide.mount();
            offcanvas.show();
            document.querySelector('#product-added-to-cart').addEventListener('hidden.bs.offcanvas', function () {
                document.querySelector('#product-added-to-cart').remove();
            });
            if (callback) callback();
        }
    );
}

export function resetOptions(scope)
{
    resetPreviews(scope);
    scope.querySelectorAll('.options').forEach(function (option) {
        hideOptionWarnings(option);
        switch (option.dataset.type) {
            case 'size':
                resetSizeOption(option);
                break;
            case 'customizer':
                resetCustomizerOption(option);
                break;
            case 'color':
                resetColorOption(option);
                break;
            case 'pack':
                resetPacksOption(option);
                break;
            case 'orientation':
                resetOrientationOption(option);
                break;
            case 'glass-reversed':
                resetGlassReversedOptions(option);
                break;
            case 'blind-tissue':
                resetBlindTissueOption(option);
                break;
            case 'blind-chain':
                resetBlindChainOption(option);
                break;
            case 'blind-drop':
                resetBlindDropOption(option);
                break;
            case 'blind-support-color':
                resetBlindSupportColor(option);
                break;
            case 'model':
                resetModelOption(option);
                break;
            case 'material-color':
                resetMaterialColor(option);
                break;
            case 'model-size':
                resetModelSizeOption(option);
                break;
            case 'poster-frame':
                resetPosterFrameOptions(option);
        }
    });
}

export function resetComplements(scope)
{
    scope.querySelectorAll('.complement').forEach(function (complement) {
        let input = complement.querySelector('input');
        input.dataset.quantity = 1;
        input.checked = false;
    });

    scope.querySelectorAll('.complements .complement:not(.up-selling-personalized-text)').forEach(function (complement, index) {
        if (index > 0) return;
        let input = complement.querySelector('input');
        input.checked = true
    });
}

export function resetCrossSellings(scope)
{
    scope.querySelectorAll('.cross-selling').forEach(function (crossSelling) {
        let input = crossSelling.querySelector('input');
        input.dataset.quantity = 1;
        input.checked = false;
    });
}

export function resetSizeOption(scope)
{
    let error = scope.querySelector('.general-error');
    let sizesSelected = scope.querySelectorAll('.size.selected');
    let radioButtons = scope.querySelectorAll('input[name="size"]:checked');
    let confirmButton = scope.querySelector('.confirm-button');
    let continueConfiguration = scope.querySelector('.continue-configuration');
    let addToCart = scope.querySelector('.btn-add-to-cart');
    let wallpaperCalculator = scope.querySelector('.wallpaper-calculator');
    sizesSelected.forEach((sizeSelected) => sizeSelected.classList.remove('selected'));
    radioButtons.forEach(function(radioButton) {
        radioButton.checked = false;
        if (radioButton.dataset.quantity) radioButton.dataset.quantity = 0;
    });
    if (confirmButton) confirmButton.classList.add('disabled');
    if (continueConfiguration) hideElement(continueConfiguration);
    if (addToCart && !addToCart.closest('.wallpaper-calculator')) hideElement(addToCart);
    if (wallpaperCalculator) resetWallpaperCalculator(wallpaperCalculator);
    hideElement(error);
    hideAllCollapse(scope);
    resetCustomSizeOption(scope);
    selectUniqueCustomSizeOption(scope);
    resetSizeQuantities(scope);
}

export function resetCustomSizeOption(scope)
{
    if (!scope.querySelector('.custom-size')) return;
    let optionType = scope.dataset.optionType;
    let input = scope.querySelector('.custom-size input[name="size"]');
    let elements = scope.querySelectorAll('.custom-size .custom-size-width, .custom-size .custom-size-height');

    if (optionType === 'multiple-selectable') {
        input.dataset.quantity = 0;
        let prices = scope.querySelectorAll('.custom-size .custom-price');
        let sizePreview = scope.querySelector('.custom-size .size-preview');
        let freeShippingIcon = scope.querySelector('.custom-size .tvi-free-shipping');
        let headerQuantity = scope.querySelector('.custom-size .header .quantity');
        let headerQuantityTomSelect = headerQuantity.tomselect;
        hideElement(freeShippingIcon);
        sizePreview.innerHTML = '';
        if (!headerQuantity.classList.contains('hidden')) headerQuantity.classList.add('hidden');
        if (headerQuantityTomSelect && !headerQuantityTomSelect.wrapper.classList.contains('hidden')) headerQuantityTomSelect.wrapper.classList.add('hidden');
        prices.forEach(function(priceElement) {
            let price = priceElement.querySelector('.price');
            price.innerHTML = price.dataset.defaultValue;
            hideElement(priceElement.querySelector('.loader'));
            showElement(priceElement.querySelector('.currency'));
            showElement(price);
            showElement(priceElement.querySelector('.price-of'));
        })
    } else {
        let button = scope.querySelector('.custom-size .validate');
        let price = scope.querySelector('.custom-size .custom-price');
        let priceValue = scope.querySelector('.custom-size .custom-price .value');
        let priceHeader = scope.querySelector('.custom-size').closest('.option').querySelector('.size-price');

        let priceOf = scope.querySelectorAll('.custom-size .price-of');
        if (button && !button.classList.contains('disabled')) button.classList.add('disabled');
        if (!price.classList.contains('d-none')) price.classList.add('d-none');
        priceValue.innerHTML = '';
        priceHeader.innerHTML = priceHeader.dataset.defaultPrice;

        priceOf.forEach(priceOf => showElement(priceOf));
        if (priceValue.dataset.defaultValue) {
            price.classList.remove('d-none');
            priceValue.innerHTML = priceValue.dataset.defaultValue;
        }
    }

    input.dataset.width = '';
    input.dataset.height = '';
    elements.forEach(function(element) {
        element.value = '';
        element.innerHTML = '';
    });
}

export function resetCustomizerOption(scope)
{
    let error = scope.querySelector('.general-error');
    let customText = scope.querySelector('textarea[name="customText"], input[name="customText"]');
    let customImage = scope.querySelector('input[name="custom-image"]');
    let customImageFromWeb = scope.querySelector('input[name="customImageFromWeb"]');
    let customPriceIncrement = scope.querySelector('input[name="customPriceIncrement"]');
    let svgPersonalizedText = scope.querySelector('input[name="svg-personalized-text"]');
    let validateButton = scope.querySelector('.validate');
    let previewSlide = document.querySelector(`.image-slider .splide__list .splide__slide[data-type="preview"]`);
    if (customText) customText.value = '';
    if (customImageFromWeb) customImageFromWeb.checked = false;
    if (customImage) {
        scope.querySelector('input[name="file-customImage"]').value = null;
        scope.querySelector('input[name="display-custom-image"]').value = '';
        customImage.value = '';
    }
    if (customPriceIncrement) {
        let defaultStatus = customPriceIncrement.dataset.defaultStatus;
        if (defaultStatus && defaultStatus === 'checked')
            customPriceIncrement.checked = true;
        else
            customPriceIncrement.checked = false;
    }
    if (svgPersonalizedText && previewSlide) {
        svgPersonalizedText.dataset.svg = '';
        svgPersonalizedText.dataset.preview = '';
        let image = previewSlide.querySelector('img');
        fadeOut(image, 200, function() {
            image.src = previewSlide.dataset.default;
            fadeIn(image, 200);
        });
    }
    if (validateButton) validateButton.classList.add('disabled');
    hideElement(error);
}

export function resetColorOption(scope)
{
    let productIndex = scope.dataset.productIndex;
    let input = scope.querySelector('input[name="color"]:checked');
    let color = scope.querySelector('.color.selected');
    if (input) input.checked = false;
    if (color) color.classList.remove('selected');

    let productScope = document.querySelector('.product-scope');
    let imageSlider = productScope.querySelector(`.image-slider .splide__list .splide__slide[data-type="preview"][data-product-index="${productIndex}"], .image-slider .splide__list .splide__slide[data-type="preview"]:not([data-product-index])`); //TODO TEMPORAL FIX
    if (!imageSlider) return;
    let image = imageSlider.querySelector('img');
    loadPreviewImage(productScope, productIndex, false, function(previewImage) {
        fadeOut(image, 50, function() {
            imageSlider.dataset.default = previewImage;
            image.src = previewImage;
            fadeIn(image, 50);
        });
    });
}

export function resetPacksOption(scope)
{
    let input = scope.querySelector('input[name="pack"]:checked');
    let pack = scope.querySelector('.pack.selected');
    if (input) {
        input.checked = false;
        pack.classList.remove('selected');
        scope.querySelector('input[name="pack"].default').checked = true;
        scope.querySelector('.pack.default').classList.add('selected');
        fireChangeEvent(scope.querySelector('input[name="pack"].default'), false);
        //calculatePackPrice(document.querySelector('.product-scope'), 1);
    }
}

export function resetOrientationOption(scope)
{
    scope.querySelectorAll('input[name="orientation"]').forEach(function (input) {
        let option = input.closest('.option-inline-selectable');
        if (input.classList.contains('default')) {
            input.checked = true;
            //if (!option.classList.contains('selected')) option.classList.add('selected');
        } else {
            input.checked = false;
            option.classList.remove('selected');
        }
        let productScope = document.querySelector('.product-scope');
        let imageSlider = document.querySelector(`.image-slider .splide__list .splide__slide[data-type="preview"]`);
        let image = imageSlider.querySelector('img');
        let options = getOptions(productScope);
        if (options['mirrored'] && options['mirrored'] === true) {
            image.setAttribute('style', 'transform: scaleX(-1)');
        } else {
            image.setAttribute('style', '');
        }
        loadPreviewImage(productScope, 1, false, function (previewImage) {
            fadeOut(image, 50, function () {
                imageSlider.dataset.default = previewImage;
                image.src = previewImage;
                fadeIn(image, 50);
            });
        });
    });
}

export function resetGlassReversedOptions(scope)
{
    let input = scope.querySelector('input[name="glass-reversed"]');
    let option = input.closest('.option-inline-selectable');
    input.checked = false;
    option.classList.remove('selected');
}

export function resetBlindTissueOption(scope)
{
    let optionSelected = scope.querySelector('.tissue.selected');
    let radioButton = scope.querySelector('input[name="tissue"]:checked');
    if (optionSelected) optionSelected.classList.remove('selected');
    radioButton.checked = false;
}

export function resetBlindChainOption(scope)
{
    let optionSelected = scope.querySelector('.chain-position.selected');
    let radioButton = scope.querySelector('input[name="chainPosition"]:checked');
    if (optionSelected) optionSelected.classList.remove('selected');
    radioButton.checked = false;
}

export function resetBlindDropOption(scope)
{
    let optionSelected = scope.querySelector('.drop.selected');
    let radioButton = scope.querySelector('input[name="drop"]:checked');
    if (optionSelected) optionSelected.classList.remove('selected');
    radioButton.checked = false;
}

export function resetBlindSupportColor(scope)
{
    let optionSelected = scope.querySelector('.support-color.selected');
    let radioButton = scope.querySelector('input[name="support-color"]:checked');
    if (optionSelected) optionSelected.classList.remove('selected');
    radioButton.checked = false;
}

function resetModelOption(scope)
{
    let optionSelected = scope.querySelector('.model.selected');
    let radioButton = scope.querySelector('input[name="model"]:checked');
    if (optionSelected) optionSelected.classList.remove('selected');
    radioButton.checked = false;
}

function resetMaterialColor(scope)
{
    let optionSelected = scope.querySelector('.material-color.selected');
    let radioButton = scope.querySelector('input[name="material-color"]:checked');
    if (optionSelected) optionSelected.classList.remove('selected');
    radioButton.checked = false;
}

function resetModelSizeOption(scope)
{
    let optionSelected = scope.querySelector('.model-size.selected');
    let radioButton = scope.querySelector('input[name="model-size"]:checked');
    if (optionSelected) optionSelected.classList.remove('selected');
    radioButton.checked = false;
}

function resetPosterFrameOptions(scope)
{
    scope.querySelectorAll('.frame:not(.default)').forEach( function (frame) {
        frame.classList.remove('selected');
        frame.previousElementSibling.checked = false;

        if (!frame.classList.contains('forced-disabled')) {
            frame.previousElementSibling.disabled = false;
            frame.classList.remove('frameClose');
        }
    });
}

export function resetPreviews(scope)
{
    scope.querySelectorAll('.options .option-selectable .preview').forEach(function(preview) {
        preview.innerHTML = '';
    });
}

export function resetTools(scope)
{
    scope.querySelectorAll('.tool').forEach(function (tool) {
        switch (tool.dataset.type) {
            case 'calculator':
                resetWallpaperCalculator(tool);
                break;
        }
    });
}

export function resetWallpaperCalculator(scope)
{
    if (!scope) return;
    let width = scope.querySelector('.wallpaper-calculator-width');
    let height = scope.querySelector('.wallpaper-calculator-height');
    let rollSizes = scope.querySelectorAll('input.wallpaper-calculator-roll-size');
    let size = scope.querySelector('input[data-type="size"]');
    let quantity = scope.querySelector('input.quantity');
    let glueComplement = document.querySelector('#product-page input[name="complement"][value="486"]');
    let tool = scope.querySelector('.wallpaper-calculator-tool');
    let result = scope.querySelector('.wallpaper-calculator-result');
    if (width) width.value = "";
    if (height) height.value = "";
    if (size) {
        size.dataset.width = '';
        size.dataset.height = '';
        size.dataset.previewText = '';
    }
    if (glueComplement) glueComplement.dataset.quantity = 1;
    if (quantity)  {
        quantity.value = 0;
        quantity.classList.remove('quantity-add-to-cart');
    }
    tool.classList.remove('d-none');
    result.classList.add('d-none');
    rollSizes.forEach((size) => size.checked = false);
}

export function hideAllCollapse(scope)
{
    scope.querySelectorAll('.box-collapse:not(.unique-option, .show-expanded)').forEach(function(box) {
        let collapse = bootstrap.Collapse.getInstance(box.querySelector('.collapse'));
        if (collapse) collapse.hide();
    });
}

export function hideOptionErrors(scope)
{
    scope.querySelectorAll('.error').forEach(error => hideElement(error));
}

export function hideOptionWarnings(scope)
{
    scope.querySelectorAll('.warning').forEach(warning => hideElement(warning));
}

export function selectUniqueCustomSizeOption(scope)
{
    let option = scope.querySelector('.custom-size.unique-option');
    if (!option) return;
    let input = option.querySelector('input[name="size"]');
    let quantity = option.querySelector('.quantity-selector');
    option.classList.add('selected');
    input.checked = true;
    if (quantity && !quantity.classList.contains('quantity-add-to-cart')) quantity.classList.add('quantity-add-to-cart');
}

export function expandCollapseBox(scope)
{
    let option = scope.querySelector('.custom-size.show-expanded');
    if (!option) return;
    let header = option.querySelector('.header');
    let collapse = bootstrap.Collapse.getOrCreateInstance(option.querySelector('.collapse'));
    collapse.show();
    header.removeAttribute('data-bs-target');
}

export function resetSizeQuantities(scope)
{
    let quantities = scope.querySelectorAll('.quantity-selector');
    quantities.forEach(function(quantitySelector) {
        if (quantitySelector.tagName === 'SELECT') {
            quantitySelector.value = 0;
            quantitySelector.classList.remove('quantity-add-to-cart');
            quantitySelector.classList.remove('disabled');
            quantitySelector.classList.remove('opacity-50');
            quantitySelector.querySelector('option[value="0"]').disabled = false;
            if (quantitySelector.tomselect) quantitySelector.tomselect.sync();
        } else {
            let quantity = quantitySelector.querySelector('input[name="quantity"]');
            if (!quantity) return;
            quantity.min = 0;
            quantity.value = 0;
            quantity.classList.remove('quantity-add-to-cart');
            quantity.disabled = false;
            quantitySelector.classList.remove('disabled');
            quantitySelector.classList.remove('opacity-50');
            fireChangeEvent(quantity, true);
        }
    });
}

export function sendEventsAddToCart(items)
{
    let fbqData = {
        content_type: 'product',
        content_name: '',
        content_ids: [],
        contents: [],
        value: 0,
        currency: ''
    };

    items.forEach(function(item) {
        dataLayerAddProduct(item);
        ecAddProduct(item.productId, item.productName, item.price, item.quantity, item.color, item.size, item.orientation);
        ecSetAction('add');
        trackEvent('ProductPage', 'AddToCart', item.productName);
        if (window.fbq) {
            if (!fbqData.content_name) {
                fbqData.content_name = item.productName;
                fbqData.currency = item.currency;
            }
            fbqData.content_ids.push(item.reference);
            fbqData.value = fbqData.value + (item.price * item.quantity);
            fbqData.contents.push({id: item.reference, quantity: item.quantity});
        }
        if (window.pintrk) {
            pintrk('track', 'AddToCart', {
                value: item.price,
                order_quantity: item.quantity,
                currency: item.currency
            });
        }
    });

    if (window.fbq) {
        fbq('track', 'AddToCart', fbqData);
        sendFacebookConversionsEvent('AddToCart', fbqData);
    }
}

export function trackProductPageGa4Events(event, extraData)
{
    let richCard = document.querySelector('script[type="application/ld+json"]');
    let sku = '';
    let name = '';
    let productType = '';
    if (richCard) {
        let jsonld = JSON.parse(richCard.innerText);
        sku = jsonld ? jsonld.sku || '' : '';
        name = jsonld ? jsonld.name || '' : '';
        productType = (jsonld ? jsonld.category || '' : '').toLowerCase();
    } else {
        let popup = document.getElementById('product-popup');
        sku = popup ? popup.dataset.productReference : '';
        name = popup ? popup.dataset.productName : '';
        // TODO: SET ON NEW LISTINGs
        productType = ''; //popup ? popup.dataset.productType : '';
    }
    switch (event) {
        case 'ImageZoom':
            ga4TrackSimpleEvent({
                "event": 'image_zoom',
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'SampleImage':
            ga4TrackSimpleEvent({
                "event": 'sample_image',
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'Complement':
            ga4TrackSimpleEvent({
                "event": 'select_complement',
                "complement": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'complement',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'CrossSelling':
            ga4TrackSimpleEvent({
                "event": 'select_cross_selling',
                "complement": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'cross_selling',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'ProductInformation':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'product_information',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'AddToCartPopup':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'add_to_cart_popup',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'AddToCart':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'add_to_cart',
                "product_type": productType
            });
            break;
        case 'AddToCartFromSizes':
            ga4TrackSimpleEvent({
                "event": 'select_size',
                "method": 'add_to_cart',
                "product_type": productType
            });
            break;
        case 'AddToCartError':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "event_type": extraData,
                "method": 'add_to_cart_error',
                "product_type": productType
            });
            break;
        case 'ContinueConfiguration':
            ga4TrackSimpleEvent({
                "event": 'select_size',
                "method": 'continue_configuration',
                "product_type": productType
            });
            break;
        case 'UnitSelector':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'unit_selector',
                "product_type": productType
            });
            break;
        case 'CustomSizeConfirm':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'custom_size_confirm',
                "product_type": productType
            });
            break;
        case 'CustomSize':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'custom_size',
                "product_type": productType
            });
            break;
        case 'OpenProductOffCanvas':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'open',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'GlassReversed':
            if (extraData === 'add') {
                ga4TrackSimpleEvent({
                    "event": 'select_glass_reversed',
                    "product_id": sku,
                    "product_type": productType,
                    "product_name": name
                });
            } else {
                ga4TrackSimpleEvent({
                    "event": 'unselect_glass_reversed',
                    "product_id": sku,
                    "product_type": productType,
                    "product_name": name
                });
            }
            break;
        case 'Size':
            ga4TrackSimpleEvent({
                "event": 'select_size',
                "size": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'Color':
            ga4TrackSimpleEvent({
                "event": 'select_color',
                "color": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'WidthError':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "width_error",
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'HeightError':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "height_error",
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'OpenProductOptionInfo':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "open_more_info",
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'CloseProductOptionInfo':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "close_more_info",
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'BlindTissue':
            ga4TrackSimpleEvent({
                "event": 'select_blind_tissue',
                "tissue": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'BlindChain':
            ga4TrackSimpleEvent({
                "event": 'select_blind_chain',
                "chain": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'BlindDrop':
            ga4TrackSimpleEvent({
                "event": 'select_blind_drop',
                "drop": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'Model':
            ga4TrackSimpleEvent({
                "event": 'select_model',
                "model": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'ModelSize':
            ga4TrackSimpleEvent({
                "event": 'select_model_size',
                "model_size": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'MaterialColor':
            ga4TrackSimpleEvent({
                "event": 'select_material_color',
                "material_color": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'OpenModelSizeInformation':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "open_model_size_info",
                "product_type": productType,
            });
            break;
        case 'CloseModelSizeInformation':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "close_model_size_info",
                "product_type": productType,
            });
            break;
        case 'SwitchMultiProduct':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "close_model_size_info",
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'AddToCartMultiProductError':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'add_to_cart_multi_product_error',
                "product_type": productType,
                "content_type": extraData
            });
            break;
    }
}
let ajaxPreviewImage = {};
let timerPreviewImage = {};
export function loadPreviewImage(scope, productIndex, photomontage, success, error)
{
    if (ajaxPreviewImage[productIndex]) ajaxPreviewImage[productIndex].abort();
    clearTimeout(timerPreviewImage[productIndex]);
    timerPreviewImage[productIndex] = setTimeout(function() {
        let productId = scope.dataset.productId;
        let options = getOptions(scope);

        if (photomontage && !options['composition']) return;

        ajaxPreviewImage[productIndex] = post(
            '/ajax/website/product/load-preview-image',
            {
                productId: productId,
                options: options,
                photomontage: photomontage,
                productIndex: productIndex,
            },
            function (response) {
                if (scope.dataset.scope == 'product-page') success(response.image)
                ajaxPreviewImage[productIndex] = null;
            },
            function() {
                ajaxPreviewImage[productIndex] = null;
                if (error) error();
            }
        );
    }, 200);
}

let ajaxUpdatePackPrices = null;
export function calculatePackPrice(scope, productIndex)
{
    if (ajaxUpdatePackPrices) {
        ajaxUpdatePackPrices.abort();
    }
    let productId = scope.dataset.productId;
    let options = getOptions(scope, productIndex);
    let pack = options.pack;

    if (pack) {
        ajaxUpdatePackPrices = post(
            '/ajax/website/product/update-pack-prices',
            {
                productId: productId,
                pack: pack
            },
            function (response) {
                displayPackPrices(scope, productIndex, response.prices);
                ajaxUpdatePackPrices = null;
            },
            function() {
                ajaxUpdatePackPrices = null;
            }
        );
    }
}

function displayPackPrices(scope, productIndex, prices)
{
    prices.forEach(function (element) {
        let id = element.id;
        let price = element.price;
        let oldPrice = element.oldPrice;
        let labels = element.labels;
        let input = scope.querySelector(`.options[data-type="size"][data-product-index="${productIndex}"] input[name="size"][value="${id}"], .options[data-type="size"]:not([data-product-index]) input[name="size"][value="${id}"]`); //TODO TEMPORAL FIX
        let label = scope.querySelector(`label[for="${input.id}"]`);

        input.dataset.price = price;
        label.querySelector('.size-price').innerHTML = price;
        if (oldPrice) {
            label.querySelector('.size-old-price').innerHTML = oldPrice;
        }
        //label.querySelector('.size-labels').innerHTML = labels;
    });
}

function upSellingPersonalizedTextData(element)
{
    return {
        id: element.value,
        price: element.dataset.price,
        quantity: element.dataset.quantity,
        linkToParent: element.dataset.linkToParent,
        options: {
            width: element.dataset.width,
            height: element.dataset.height,
            personalizedText: element.dataset.text,
            font: element.dataset.font,
            mirrored: element.dataset.mirrored,
            color: element.dataset.color,
            personalizedSvg: element.dataset.svg,
            rightToLeft: element.dataset.rightToLeft,
            isUpselling: true,
            personalizedSize: true
        }
    }
}

export function getSvgPersonalizedText(scope, callback)
{
    let text = scope.querySelector('textarea[name="customText"], input[name="customText"]').value;
    let color = scope.querySelector('input[name="color"]:checked');
    color = color ? color.value : null;

    post(
        '/ajax/website/product/get-svg-personalized-text',
        {
            id: scope.dataset.productId,
            text: text,
            color: color,
        },
        function(response) {
            callback(response.svg, response.svgPreview);
        }
    );
}

export function updateFrameSizes(element)
{
    let scope = element.closest('.product-scope');
    let width = parseFloat(element.dataset.width);
    let height = parseFloat(element.dataset.height);

    scope.querySelectorAll('input[name="poster-frame"]').forEach(function(frame) {
        let frameLabel = frame.nextElementSibling;
        let frameSizes = frame.dataset.sizes ? JSON.parse(frame.dataset.sizes) : [];
        let isAvailableSize = !frameSizes.length;
        let frameWidth = Math.min(width, height);
        let frameHeight = Math.max(width, height);

        frameSizes.forEach(function(frameSize) {
            if (frameSize.width === frameWidth && frameSize.height === frameHeight) isAvailableSize = true;
        });

        if (!isAvailableSize) {
            frame.dataset.width = '';
            frame.dataset.height = '';
            frame.checked = false;
            frame.disabled = true;
            frameLabel.classList.remove('selected');
            if (!frameLabel.classList.contains('frameClose')) frameLabel.classList.add('frameClose');
        } else {
            frame.dataset.width = frameWidth;
            frame.dataset.height = frameHeight;
            frame.disabled = false;
            frameLabel.classList.remove('frameClose');
        }
    });

    let onlyOneFrameAvailable = scope.querySelectorAll('input[name="poster-frame"]:not(:disabled)').length === 1;

    if (onlyOneFrameAvailable) {
        let frame = scope.querySelector('input[name="poster-frame"]:not(:disabled)');
        let frameLabel = frame.nextElementSibling;

        frame.checked = true;
        frameLabel.classList.add('selected');
        fireChangeEvent(frame, false);
    }
}