import {post} from "./ajax";

export function serialize(form)
{
    let data = {};
    let formData = new FormData(form);
    for (let [key, value] of formData) {
        data[key] = value;
    }

    return data;
}

export function sendForm(form, success, error)
{
    let url = form.getAttribute('action');
    let data = serialize(form);
    post(
        url,
        data,
        success,
        error
    )
}