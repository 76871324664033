import {
    acceptAllCookies, acceptAnalyticsCookies,
    acceptBasicCookies, acceptMarketingCookies, acceptNoCookies,
    deleteAllCookies,
    getCookie, onPageHideCookieListener, setCookie,
    showModalConfigCookies, showModalOldConfigCookies
} from "../../webpack/modules/cookies";
import {changeEvent, clickEvent, customEvent, fireClickEvent} from "../../webpack/modules/events";
import {hideModal} from "../../webpack/modules/modal";
import {post} from "../../webpack/modules/ajax";
import {slideUp} from "../../webpack/modules/element-visibility";
import {hideOffcanvas} from "../../webpack/modules/offcanvas";
import {launchNewsletterPopup} from "../Newsletter/main";

if (!getCookie('_tv_cookie_choice')) {
    acceptNoCookies();
    sessionStorage.removeItem('_tv_user_country');
    sessionStorage.removeItem('_tv_user_cookie_country');
}
clickEvent('#cookies-message .show-modal-config-cookies', true, function() {
    let message = document.getElementById('cookies-message');
    slideUp(message);
    showModalOldConfigCookies();
});

window.addEventListener('pagehide', onPageHideCookieListener);

clickEvent('#modal-cookies .accept-all-cookies', true, function() {
    acceptAllCookies(365);
    hideModal('modal-cookies');
    launchNewsletterPopup();
});

clickEvent('#modal-cookies .decline-all-cookies', true, function() {
    acceptAnalyticsCookies(365);
    acceptBasicCookies(365);
    hideModal('modal-cookies');
    launchNewsletterPopup();
});

clickEvent('#offcanvas-cookies .accept-all-cookies', true, function() {
    acceptAllCookies(365);
    hideOffcanvas('offcanvas-cookies');
});

clickEvent('#offcanvas-cookies .accept-basic-analytics-cookies', true, function() {
    deleteAllCookies();
    acceptAnalyticsCookies(7);
    acceptBasicCookies(7);
    hideOffcanvas('offcanvas-cookies');
    launchNewsletterPopup();
    dataLayer.push({
        event: 'cookies',
        method: 'accept_analytics_and_basic'
    });
});

clickEvent('#cookies-message .accept-all-cookies', true, function(element) {
    acceptAllCookies(365);
    slideUp(element.closest('#cookies-message'));
});

/*customEvent('hide.bs.modal', '#modal-cookies', true, function() {
    acceptNoCookies();
});*/

clickEvent('#modal-cookies .signin-cookies', true, function (element) {
    acceptAllCookies(365, 'cookies_login');
    // hideModal('modal-cookies');
    hideOffcanvas('offcanvas-cookies');
    setTimeout(() => {
        let objectDesktop = document.querySelector('#header-user');
        let objectMobile = document.querySelector('#header-mobile-user');

        if (objectMobile) {
            if (objectMobile.offsetParent !== null) {
                location.replace(objectMobile.href);
            }
        }
        if (objectDesktop) {
            fireClickEvent(objectDesktop, false);
        }
    }, 50);
});

clickEvent('#cookies-message .close', true, function(element) {
    acceptNoCookies();
});

clickEvent('#modal-cookies .show-modal-config-cookies', true, function() {
    hideModal('modal-cookies');
    showModalConfigCookies();
});
clickEvent('#offcanvas-cookies .show-modal-config-cookies', true, function() {
    hideOffcanvas('offcanvas-cookies');
    showModalConfigCookies();
});
clickEvent('#cookies-config .show-modal-config-cookies', true, function() {
    showModalConfigCookies();
});

clickEvent("#save-configuration-cookies", true, function() {
    if (fullCookiesAccepted()) {
        acceptAllCookies(365, 'cookies_config');
    } else if (noCookiesAccepted()) {
        acceptNoCookies();
        dataLayer.push({
            event: 'cookies_config',
            method: 'not_accept_any'
        });
    } else {
        deleteAllCookies();
        if (analyticsCookiesAccepted()) acceptAnalyticsCookies(7);
        if (marketingCookiesAccepted()) acceptMarketingCookies(7);
        if (basicCookiesAccepted()) acceptBasicCookies(7);

        if (analyticsCookiesAccepted() && marketingCookiesAccepted()) {
            dataLayer.push({
                event: 'cookies_config',
                method: 'accept_all'
            });
        } else if (analyticsCookiesAccepted()) {
            dataLayer.push({
                event: 'cookies_config',
                method: 'accept_analytics_and_basic'
            });
        } else if (marketingCookiesAccepted()) {
            dataLayer.push({
                event: 'cookies_config',
                method: 'accept_marketing_and_basic'
            });
        } else {
            dataLayer.push({
                event: 'cookies_config',
                method: 'accept_basic_only'
            });
        }
    }

    hideModal('modal-config-cookies');
    launchNewsletterPopup();
    //location.replace(location.href);
});

customEvent('hide.bs.modal', '#modal-config-cookies', true, function() {
    if (!getCookie('_tv_cookie_choice')) {
        acceptAllCookies(30, 'cookies_config_close');
        launchNewsletterPopup();
    }
});

changeEvent('#cookie-configuration-all', true, function(element) {
    let marketingCHeck = document.querySelector('#cookie-configuration-marketing');
    if (element.checked) {
        marketingCHeck.disabled = true;
        marketingCHeck.checked = true;
    } else {
        marketingCHeck.disabled = false;
    }
});

if (!getCookie('_tv_uuid') /*&& getCookie('_tv_cookie_consent') !== ""*/) {
    if (!Date.now) {
        Date.now = function now() {
            return new Date().getTime();
        };
    }
    post(
        '/ajax/website/get-uuid',
        {},
        function(data) {
            let userId = data.userId;
            if (userId) {
                if(!localStorage.getItem('_tv_cookie_choice')){
                    setCookie('_tv_uuid', Math.floor(Date.now() /1000) + '_' + userId, 3650);
                }
            }
        },
    );
}

function fullCookiesAccepted()
{
    return allCookiesAccepted() || (analyticsCookiesAccepted() && marketingCookiesAccepted() && basicCookiesAccepted());
}

function noCookiesAccepted()
{
    return !allCookiesAccepted() && !analyticsCookiesAccepted() && !marketingCookiesAccepted() && !basicCookiesAccepted();
}

function allCookiesAccepted()
{
    return document.querySelector('#cookie-configuration-all').checked;
}

function analyticsCookiesAccepted()
{
    //return document.querySelector('#cookie-configuration-analytics').checked;
    return true;
}

function marketingCookiesAccepted()
{
    return document.querySelector('#cookie-configuration-marketing').checked;
}

function basicCookiesAccepted()
{
    return document.querySelector('#cookie-configuration-basic').checked;
}